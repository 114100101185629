<template>
  <v-menu transition="slide-y-transition" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on">
        mdi-dots-vertical
      </v-icon>
    </template>

    <v-list>
      <slot name="listExtension"></slot>

      <v-list-item v-if="canRecibo">
        <v-list-item-content>
          <v-btn
            small
            outlined
            :color="variables.colorPrimary"
            @click="$emit('onEmitirRecibo')">
            Emitir Recibo
          </v-btn>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="canEdit">
        <v-list-item-content>
          <v-btn
            small
            outlined
            :color="variables.colorPrimary"
            @click="$emit('onEdit')">
            Editar
          </v-btn>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="canDelete">
        <v-list-item-content>
          <v-btn
            small
            outlined
            :color="variables.colorDanger"
            @click="$emit('onDelete')">
            Excluir
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'OrganizzeActionButtom',
  props: {
    canEdit: {
      type: Boolean,
      default: false,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: false,
      required: true
    },

    canRecibo: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  directives: {},
  components: {},
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {
    variables: () => variables
  },
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-icon {
    &:hover {
      color: $colorPrimary;
    }
  }

  .v-list-item__content {
    padding: 0px;
  }
</style>